body {
	margin: 0;
	padding: 0;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	text-rendering: geometricprecision;
	box-sizing: border-box;
}

.app-container {
	margin-top: 50px;
}

@media print {
	.app-container {
		margin-top: 0px;
	}
}

.tippy-tooltip {
	box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.5) !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
	flex-grow: 1;
}

@font-face {
	font-family: "Phillies";
	src: local("Phillies"), url("./fonts/Phillies.ttf") format("truetype");
	font-weight: normal;
}

@font-face {
	font-family: "PhilliesRetro";
	src: local("PhilliesRetro"), url("./fonts/PhilliesRetro.ttf") format("truetype");
	font-weight: normal;
}

/*.datatable-body {
    width: calc(100% + 5px);
    overflow-y: scroll;
}

.datatable-body::-webkit-scrollbar {
    width: 5px;
}

.datatable-body::-webkit-scrollbar-thumb {
    background: #666;
}

.datatable-header {
    display: flex;
    width: 100%;
}

.datatable-row {
    width: 100%;
    border-top: 1px solid rgb(128, 128, 128)
}

.datatable-row:hover {
    background-color: rgba(255,255,0,0.4);
}*/
